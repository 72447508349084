import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import Toast from "react-native-toast-message";

import { Linking_linkTo, Linking_openURL } from "../LinkingHelper";
import { store } from "../../redux";
import PushNotificationsActionsEnum from "../../enums/PushNotificationActionsEnum";
import { MessagePayload } from "firebase/messaging";
import { Platform } from "react-native";

export type RemoteNotificationType =
  | FirebaseMessagingTypes.RemoteMessage
  | MessagePayload;

export const processNotification = (notification: RemoteNotificationType) => {
  console.log("processNotification", notification);

  const { data } = notification;
  switch (data?.action_type) {
    case PushNotificationsActionsEnum.LINK: {
      const link = data?.action_payload as string;
      const server = "mycopilotiq.com";
      const isCopilotIQUrl = link.includes(server);

      if (isCopilotIQUrl && Platform.OS !== "web") {
        const splitted = link.split(server);
        const appPath = splitted[1];
        Linking_linkTo(appPath);
      } else Linking_openURL(data?.action_payload as string, store.dispatch);
    }
  }
};

export const onForegroundNotificationReceived = (
  remoteMessage: RemoteNotificationType
) => {
  const { notification, data } = remoteMessage;

  const isValidActionType = Object.values(
    PushNotificationsActionsEnum
  ).includes(data?.action_type as PushNotificationsActionsEnum);

  const onActionPressed = isValidActionType
    ? () => processNotification(remoteMessage)
    : undefined;

  if (notification) {
    Toast.show({
      type: "info",
      text1: notification.title,
      text2: notification.body,
      position: "top",
      visibilityTime: 8000,
      props: {
        remoteMessage,
        onActionPressed
      }
    });
  } else {
    processNotification(remoteMessage);
  }
};
