import { initializeApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
  MessagePayload
} from "firebase/messaging";

import firebaseConfig from "../../config/firebase.json";
import EnvVars from "../../config/EnvVars";
import { useEffect } from "react";
import StorageHelper from "../StorageHelper";
import StorageEnum from "../../enums/StorageEnum";
import { PermissionsHelper_hasPushNotification } from "../PermissionsHelper";
import { onForegroundNotificationReceived } from "./PushNotificationsHelper.common";

let messaging: Messaging = null;

const storeFCMToken = async (token: string) => {
  await StorageHelper.setItem(StorageEnum.FCM_TOKEN, token);
};

export const PushNotificationsHelper_isSupported = () => {
  return isSupported();
};

export const PushNotificationsHelper_getTokenAndStore = () => {
  return new Promise(async (resolve, reject) => {
    if (messaging === null) {
      reject(new Error("Messaging is null"));
      return;
    }
    const token = await getToken(messaging, {
      vapidKey: EnvVars.REACT_APP_WEB_PUSH_CERTIFICATE
    });

    const storedToken = await StorageHelper.getItem(StorageEnum.FCM_TOKEN);
    if (storedToken !== token) {
      await storeFCMToken(token);
    }

    resolve(token);
  });
};

export const PushNotificationsHelper_init = async () => {
  const supported = await isSupported();
  if (!supported) return;
  const app = initializeApp(firebaseConfig);

  messaging = getMessaging(app);

  PermissionsHelper_hasPushNotification().then((enabled) => {
    if (enabled) {
      PushNotificationsHelper_getTokenAndStore();
    }
  });

  onMessage(messaging, (payload) => {
    console.log("Firebase: onMessage ", payload);

    onForegroundNotificationReceived(payload);

    /*const { notification, data } = payload;
    const { title, body } = notification;*/
    //alert("Notification Received: " + JSON.stringify(payload));
  });
};

export const PushNotificationsHelper_destroy = () => {};

export const usePushNotificationForeground = (
  onMessageReceived: (message: MessagePayload) => void
) => {
  useEffect(() => {
    if (messaging === null) return;

    onMessage(messaging, (payload) => {
      onMessageReceived(payload);
    });
  }, []);
};
