import { Linking } from "react-native";

import { Alert_show } from "common/helpers/AlertHelper";
import { AppDispatch } from "common/redux";

export const Linking_openURL = (url: string, dispatch: AppDispatch) => {
  Linking.openURL(url).catch((error) => {
    Alert_show({
      title: "Cannot open URL",
      content: "Please install a browser on your phone",
      dispatch
    });
  });
};

export const Linking_linkTo = (url: string) => {
  console.warn("Linking_linkTo does not work on web platform.");
};

const LinkingHelper = () => {
  return null;
};
export default LinkingHelper;
